.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#search-field {
  height: 0.04rem;
}

@media print {
  body * {
    visibility: hidden;
  }
  .divToPrint * {
    visibility: visible;
  }
  @page {
    /* Remove header */
    margin-top: 0;
  }
  .divToPrint {
    position: absolute;
    left: 0;
    top: 0;
    padding: 5px;
    margin-top: 0;
  }
  .p-0 {
    padding: 2px;
  }
  /*.divToPrint::after {*/
  /*  content: "";*/
  /*  background: url('./assets/images/gnm_logo.png') no-repeat;*/
  /*  opacity: 0.6;*/
  /*  position: fixed;*/
  /*  top: 50%;*/
  /*  left: 50%;*/
  /*  transform: translate(-50%, -50%);*/
  /*  z-index: 9999;*/
  /*  width: 200px;  !* Adjust as needed *!*/
  /*  height: 200px;  !* Adjust as needed *!*/
  /*  background-size: contain;*/
  /*}*/
}

.ant-image-preview-root {
  z-index: 99999 !important;
}

.ant-image-preview-root .ant-image-preview-mask {
  /*z-index: 99999 !important;*/
}
.ant-image-preview-close {
  z-index: 9999 !important;
}
.ant-image-preview-root .ant-image-preview-wrap {
  z-index: 9999 !important;
}
.ant-image-preview-root .ant-image-preview-content {
  z-index: 9999 !important;
}
.ant-image-preview-operations-wrapper {
  z-index: 10000 !important;

}

